import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

let router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/home",
      component: () => import("@/views/dashboard/Index"),
      children: [
        {
          path: "/roles",
          meta: {
            title: "Roles & permissions",
            permission: "View Role",
            showInNavbar: false
          },
          component: () => import("@/views/dashboard/B2B/Roles")
        },

        {
          path: "/create-team-member",
          meta: { title: "Create Member", permission: "Create Team" },
          component: () => import("@/views/dashboard/B2B/CreateTeamMember")
        },
        {
          path: "/edit-team-member/:id",
          props: true,
          meta: { title: "Edit Member", permission: "Edit Team" },
          component: () => import("@/views/dashboard/B2B/EditTeamMember")
        },
        {
          path: "/dashboard",
          meta: {
            title: "Dashboard",
            permission: "View Dashboard Page",
            showInNavbar: true,
            icon: "mdi-chart-bar-stacked"
          },
          component: () => import("@/views/dashboard/B2B/Dashboard/Dashboard")
        },
        {
          path: "/orders",
          meta: {
            title: "Orders",
            permission: "View Orders Page",
            showInNavbar: true,
            icon: "mdi-order-bool-ascending-variant"
          },
          component: () => import("@/views/dashboard/B2B/Orders")
        },
        {
          path: "/invoices",
          meta: {
            title: "Invoices",
            permission: "View Invoices Page",
            showInNavbar: true,
            icon: "mdi-receipt"
          },
          component: () => import("@/views/dashboard/B2B/Invoices")
        },

        {
          path: "/dashboard/best-sellers",
          meta: {
            title: "Best Sellers",
            permission: "Best Sellers",
            showInNavbar: false
          },
          component: () => import("@/views/dashboard/B2B/Dashboard/BestSellers")
        },

        {
          path: "/create-role",
          meta: { title: "New Role", permission: "Create Role" },
          component: () => import("@/views/dashboard/B2B/CreateRole")
        },
        {
          path: "/transactions",
          meta: {
            title: "Transactions",
            permission: "View Transactions Page",
            showInNavbar: true,
            icon: "mdi-transit-connection-variant"
          },
          component: () => import("@/views/dashboard/B2B/Transactions")
        },
        {
          path: "/wallet-logs",
          meta: {
            title: "My Wallet",
            permission: "View Wallet Page",
            showInNavbar: true,
            icon: "mdi-wallet"
          },
          component: () => import("@/views/dashboard/B2B/WalletLogs")
        },
        {
          path: "/edit-role/:id",
          component: () => import("@/views/dashboard/B2B/EditRole"),
          meta: { title: "Edit role", permission: "Edit Role" }
        },
        {
          path: "/role-details/:id",
          component: () => import("@/views/dashboard/B2B/RoleDetails"),
          meta: { title: "Role Details", permission: "View Role" }
        },
        {
          path: "/services",
          component: () => import("@/views/dashboard/B2B/Services"),
          meta: {
            title: "Services",
            permission: "View Services Page",
            showInNavbar: true,
            icon: "mdi-hammer-wrench"
          }
        },

        {
          path: "/team",
          meta: {
            title: "Team",
            permission: "View Team Page",
            showInNavbar: true,
            icon: "mdi-account-group"
          },
          component: () => import("@/views/dashboard/B2B/Team")
        },

        {
          path: "/create-order",
          component: () => import("@/views/dashboard/B2B/CreateOrder"),
          meta: { title: "Create order", permission: "Order Creation" }
        },

        {
          path: "/branches",
          meta: {
            title: "Branches",
            permission: "View Branches Page",
            showInNavbar: true,
            icon: "mdi-directions-fork"
          },
          component: () => import("@/views/dashboard/B2B/Branches")
        },
        {
          path: "/helpdesk",
          meta: { title: "Help Desk", permission: "View Helpdesk Page" },
          component: () => import("@/views/dashboard/B2B/HelpDesk")
        },
        {
          path: "/edit-branch/:id",
          meta: { title: "Edit Branch" },
          props: true,
          component: () => import("@/views/dashboard/B2B/EditBranch")
        },
        {
          path: "/create-branch",
          meta: { title: "Create Branch", permission: "Create Branch" },
          props: true,
          component: () => import("@/views/dashboard/B2B/CreateBranch")
        },

        {
          path: "/order-details/:id",
          meta: { title: "Order info", permission: "View Orders Page" },
          component: () => import("@/views/dashboard/B2B/OrderDetails")
        },

        ,
        {
          path: "/invoice-detail/:id",
          props: true,
          meta: { title: "Invoice Details", permission: "View Invoice Page" },
          component: () => import("@/views/dashboard/B2B/InvoiceDetail")
        },

        {
          path: "/settings",
          meta: {
            title: "Settings",
            permission: "View Settings Page",
            showInNavbar: true,
            icon: "mdi-cog-transfer"
          },
          component: () => import("@/views/dashboard/B2B/Settings")
        },
        {
          path: "/company-profile",
          meta: {
            title: "Company Profile",
            permission: "View Company Profile Page",
            showInNavbar: false
          },
          component: () => import("@/views/dashboard/B2B/CompanyProfile")
        },
        {
          path: "/welcome",
          meta: {
            title: "Welcom Page",
            showInNavbar: false,
            icon: "mdi-order-bool-ascending-variant"
          },
          component: () => import("@/views/dashboard/B2B/welcome")
        }
      ]
    },
    {
      path: "/",
      component: () => import("@/views/login")
    },
    {
      path: "/forgotPassword",
      component: () => import("@/views/forgetPassword")
    },
    {
      path: "/resetPassword",
      component: () => import("@/views/resetPassword")
    },
    {
      path: "/dashboard",
      component: () => import("@/views/dashboard/B2B/B2bDashboard")
    }
  ]
});

// router.beforeEach((to,from,next) => {
//     store.dispatch('getAdminpermissions')
//     console.log('permissions')
//     if ( to.meta.permission === 'View Order Page') {

//         next()
//     }
//     else
//         next('/')

// }

// )

router.afterEach((to, from) => {
  Vue.nextTick(() => {
    document.title = to.meta.title ? to.meta.title : "Munjz b2b";
  });
});

export default router;

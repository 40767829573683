<template>
	<v-container id="munjz-nav-bar" app fluid class="mt-0 white">
		<v-row align-center justify-md="end" class="py-2">
			<v-col cols="2" sm="2" class="me-auto">
				<div class="d-flex justify-center justify-md-start">
					<div
						style="overflow: hidden; max-width: 48px"
						class="d-flex justify-center rounded-lg munjz-accent-light"
					>
						<v-img :src="companyLogo"></v-img>
					</div>
				</div>
			</v-col>

			<v-flex xs8 sm4 class="offset-sm-2">
				<div
					class="
						pa-2
						d-flex
						justify-center
						text-center
						rounded-pill
						elevation-1
					"
					style="border: 1px solid skyblue"
				>
					<span class="primary--text text-h6 text-sm-h5 text-center">
						Welcome Back, 😍 {{ `${userName} @ ${companyName}` }}
					</span>
				</div>
			</v-flex>

			<v-flex xs2 md2 lg1 class="my-4 my-sm-0">
				<div class="d-flex justify-center">
					<!-- <v-btn class="blue-grey lighten-4 mx-2" small fab text>
            <v-icon color="blue-grey lighten-2">mdi-bell</v-icon>
          </v-btn>-->
					<v-btn small fab text class="munjz-accent" @click="logOut">
						<v-icon color="white">mdi-logout</v-icon>
					</v-btn>
				</div>
			</v-flex>
		</v-row>
		<v-layout wrap row class="munjz-bg-grey-dark justify-center align-center">
			<v-flex class="pa-2">
				<div class="d-flex flex-wrap justify-center">
					<div
						v-for="(navbarLink, idx) in navbarLinks"
						:key="idx"
						class="d-inline-flex flex-column align-center mx-2 mx-sm-4"
					>
						<v-btn
							icon
							class="ma-0 transparent"
							exact-active-class="munjz-accent-light"
							:to="navbarLink.path"
						>
							<v-icon
								:color="isCurrentRoute(navbarLink) ? 'primary' : '#414141'"
								medium
								class="ma-0 pa"
								>{{ navbarLink.meta.icon }}</v-icon
							>
						</v-btn>

						<span
							@click="$router.push(navbarLink.path)"
							style="cursor: pointer; user-select: none"
							class="text-h4"
							:class="isCurrentRoute(navbarLink) && 'primary--text'"
						>
							{{ navbarLink.meta.title }}
						</span>
					</div>
				</div>
			</v-flex>
		</v-layout>
	</v-container>
</template>
<script>
export default {
	name: "ztoolbar",
	data() {
		return {
			navbarLinks: [],
			userName: localStorage.getItem("name"),
			permissions: this.$store.userPermissions,
			companyName: "Your Company",
			companyLogo: localStorage.getItem("companyLogo"),
		};
	},
	mounted() {},
	created() {
		this.companyName = localStorage.getItem("companyName") || "Your Company";
		this.companyLogo =
			localStorage.getItem("companyLogo") ||
			"https://www.logodesign.net/logo/abstract-box-forming-building-2125ld.png";
		this.navbarLinks = this.$router
			.getRoutes()
			.filter(
				(route) =>
					route.meta.showInNavbar === true &&
					this.hasPermission(route.meta.permission)
			);
	},
	watch: {
		companyLogo() {
			this.companyLogo = localStorage.getItem("companyLogo");
		},
	},
	methods: {
		hasAccess(permission) {
			if (Array.isArray(this.permissions))
				return this.permissions.includes(permission);
			else return false;
		},
		isCurrentRoute(navbarLink) {
			return this.$route.path === navbarLink.path;
		},
		logOut() {
			return this.$router.push({ path: "/" });
		},
	},
};
</script>

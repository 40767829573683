<template>
	<div style="padding-inline: 250px">
		<router-view />
	</div>
</template>

<script>
import ZToolBar from "./views/dashboard/components/core/ZToolBar.vue";
export default {
	name: "App",
	components: { "z-tool-bar": ZToolBar },
};
</script>

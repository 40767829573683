// =========================================================
// * Vuetify Material Dashboard - v2.1.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vuetify-material-dashboard
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
// import './plugins/base'
// import './plugins/chartist'
// import './plugins/vee-validate'
import vuetify from "./plugins/vuetify";
import i18n from "./i18n";
import * as VueGoogleMaps from "vue2-google-maps";
// import VueQr from 'vue-qr'
import moment from "moment";
import "./mixins/generalMixin";
import JsonExcel from "vue-json-excel";

window.axios = axios;
window.router = router;

// axios.defaults.baseURL = 'https://munjz.net/api/v3'
var currentURL = window.location.toString();
if (currentURL.indexOf("localhost") > -1) {
  // window.serverURL = "http://munjz.test/";
  // window.serverURL = "http://15.185.154.94:55/";
  window.serverURL = "https://4munjz.com";
  axios.defaults.baseURL = window.serverURL;
} else if (currentURL.indexOf("munjzsouq") > -1) {
  window.serverURL = "https://4munjz.com";
  // window.serverURL = "http://15.185.154.94:55/";
  axios.defaults.baseURL = window.serverURL;
} else {
  window.serverURL = "https://4munjz.com";
  axios.defaults.baseURL = window.serverURL;
}

// axios.defaults.headers.common['Accepts'] = 'application/json'
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
axios.defaults.headers.common["Access-Control-Allow-Headers"] =
  "Origin, X-Requested-With, Content-Type, Accept";

Vue.prototype.$IsSignedIn = localStorage.getItem("signedin");
Vue.prototype.$role = localStorage.getItem("role");

Vue.config.productionTip = false;
Vue.filter("momentDate", function (datevalue) {
  return moment(datevalue).format("MMM Do YYYY");
});
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyD7fPnnVpJ5PpHWF4YRNzvU1-xuR1UgI4A", // "AIzaSyDBr8fHyX4CFO0PMq4dxJlhPH8RrjXfyN8",
    libraries: "places", // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)
    // If you want to set the version, you can do so:
    // v: '3.26',
  },
});
// cls
Vue.component("downloadExcel", JsonExcel);

new Vue({
  router,
  store,
  vuetify,
  i18n,

  render: (h) => h(App),
}).$mount("#app");

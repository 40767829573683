import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    barColor: "rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)",
    barImage:
      "https://demos.creative-tim.com/material-dashboard/assets/img/sidebar-1.jpg",
    drawer: null,
    selectedBranch: null,
    branchesList: [],
    userPermissions: []
  },
  mutations: {
    SET_SELECTED_BRANCH(state, payload) {
      state.selectedBranch = payload;
      localStorage.setItem("selectedBranch", JSON.stringify(payload));
    },
    SET_BRANCHES_LIST(state, payload) {
      state.branchesList = payload;
      localStorage.setItem("branchesList", JSON.stringify(payload));
    },
    SET_USER_PERMISSIONS(state, payload) {
      state.userPermissions = payload;
    }
  },
  actions: {
    formatTocurrency({ commit }, val) {
      //   console.log("currency", val);

      return `${val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
    },
    getSelectedBranch({ commit }, branch) {
      commit("SET_SELECTED_BRANCH", branch);
    },
    setBranchesList({ commit }, branches) {
      commit("SET_BRANCHES_LIST", branches);
    },
    setUserPermissions({ commit }, permissions) {
      commit("SET_USER_PERMISSIONS", permissions);
    }
  },
  getters: {
    getUserPermissions(state) {
      return state.userPermissions;
    }
  }
});

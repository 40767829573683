/* eslint-disable */
import Vue from "vue";
Vue.mixin({
  methods: {
    getAdminPermissions() {
      var token = localStorage.getItem("token");
      axios
        .get("/api/v3/b2b/permissions/get-permissions-by-user-id", {
          headers: { "X-Authorization": token },
          params: { user_id: localStorage.getItem("userid") },
        })
        .then((response) => {
          if (response.data.status == 200) {
            localStorage.setItem(
              "current_user_permissions",
              JSON.stringify(response.data.admin_permissions)
            );
            //   localStorage.setItem("logged_user", JSON.stringify(logged_user));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    hasPermission(permissionName) {
      let permissions;
      let partner_permissions = JSON.parse(
        localStorage.getItem("current_user_permissions") || "[]"
      );

      let parent_id = localStorage.getItem("parent_id");
      if (parent_id == 0) {
        return true;
      } else {
        permissions = partner_permissions.filter((permission) => {
          return permission.name == permissionName;
        });
        if (permissions.length > 0) {
          return true;
        }
        return false;
      }
    },
    getFloatNumber(num) {
      console.log(
        "isNan: " + isNaN(parseFloat(num)) + " --- " + parseFloat(num)
      );
      return isNaN(parseFloat(num)) ? 0 : parseFloat(num);
    },
  },
});
